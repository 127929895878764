import { ref } from 'vue'; // Import ref if you want to use a reactive variable
import { GET, POST, AUTHJWT } from "@/services/API";
import { AxiosResponse } from "axios";
import { Response } from "@/services/Response";

const GRANT_TYPE = 'password';

export async function login(email: string, password: string): Promise<AxiosResponse> {
    return await AUTHJWT({
        username: email,
        password: password,
        client_id: process.env.VUE_APP_OAUTH_CLIENT_ID,
        client_secret: process.env.VUE_APP_OAUTH_CLIENT_SECRET,
        grant_type: GRANT_TYPE
    });
}

export async function getUser(): Promise<any> {
    return await GET('/profile');
}

export async function readRole(): Promise<Response> {
    return await GET('accesscontrol/readrole', {});
}

export async function readModule(): Promise<Response> {
    return await GET('accesscontrol/readmodule', {});
}

export async function updateRolePermission(data: { any }): Promise<Response> {
    return await POST('accesscontrol/updaterolepermission', data);
}

export async function createModule(data: { any }): Promise<Response> {
    return await POST('accesscontrol/createmodule', data);
}

export async function updateModule(payload: { any }): Promise<Response> {

    return await POST('accesscontrol/updatemodule/' + payload['entityid'], payload['data']);
}

export async function readPermission(): Promise<Response> {
    return await GET('accesscontrol/readpermission', {});
}

export async function createPermission(data: { any }): Promise<Response> {
    return await POST('accesscontrol/createpermission', data);
}

export async function updatePermission(payload: { any }): Promise<Response> {

    return await POST('accesscontrol/updatepermission/' + payload['entityid'], payload['data']);
}

export function isUserHasPermision(permCodes: string[]): boolean {
    const permissions = JSON.parse(localStorage.getItem("permissions") || "[]");
    return permCodes.some(permCode => permissions.includes(permCode));
}
export function isAdminUser(): boolean {
    const userdata = JSON.parse(localStorage.getItem("userdata") || "[]");
    return userdata.role_id === 1;
}
export function getUserData() {
    return JSON.parse(localStorage.getItem("userdata") || "[]");
}
