import {formCreate, formData, updateProductPlugins, updateProductSettings, triggerBuildPipeline, formCreatePlugins, syncPluginsFromGit} from "@/services/ProductSettingService";
import {getPluginVersions} from "@/services/PluginService";

export const ProductSetting = {
    namespaced: true,
    state: {
        formCreate: {
            customers: [],
            software: '',
            branch_versions: [],
            tag_versions: [],
            plugins: [],
            prices: [],
            maintainers: [],
            types: []
        },
        product: {
            product_id: 0,
            product_name: '',
            git_url: '',
            supported_version: '',
            supported_version_type: 1, 
            development_schedule_hour: 2,
            development_schedule_day: '*',
            development_schedule_month: '*',
            staging_schedule_hour: 3,
            staging_schedule_day: '1,14',
            staging_schedule_month: '*',
            production_schedule_hour: 2,
            production_schedule_day: '14',
            production_schedule_month: '*',
            plugin_versions: [],
            selected_plugins: [],
            availability: 'private',
            customer: '',
            product_price: '',
            maintainer_id: ''
        },
        selectedEnvironment: 'dev'
    },
    mutations: {
        updateProductField(state, { field, value }) {
            state.product[field] = value;
        },
        updateProductSoftwareField(state, { field, value }) {
            state.product.software[field] = value;
        },
        updateFormCreateField(state, { field, value }) {
            state.formCreate[field] = value;
        },
        updateField(state, { field, value }) {
            state[field] = value;
        },
        updatePlugins(state, { id, field, value }) {
            state.formCreate.plugins.find((plugin) => plugin.id === id)[field] = value;
            state.formCreate.plugins.find((plugin) => plugin.id === id).isEdited = true;
        },
        clearPluginVersions(state, pluginId) {
            const selectedPlugin = state.formCreate.plugins.find((plugin) => plugin.id === pluginId);
            selectedPlugin.pluginVersions = [];
        },
        setPluginVersions(state, { pluginId, versions }) {
            const selectedPlugin = state.formCreate.plugins.find((plugin) => plugin.id === pluginId);
            selectedPlugin.branch_versions = versions;
        },
        setProductData(state, data) {
            state.product = data;
        },
        setFormCreateData(state, formCreateData) {
            state.formCreate = formCreateData;
        },
        setFormCreatePlugins(state, plugins){ // set versions of plugins that already selected and plugins that not selected
            state.product.selected_plugins = plugins.selected_plugins;        
            state.formCreate.plugins = plugins.plugins;            
            
        }
    },
    actions: {
        async updateProductField({ commit }, { field, value }) {
            commit('updateProductField', { field, value });
        },
        async updateFormCreateField({ commit }, { field, value }) {
            commit('updateFormCreateField', { field, value });
        },
        async updatePlugins({ commit }, { id, field, value }) {
            commit('updatePlugins', { id, field, value });
        },
        async updateProductSoftwareField({ commit }, { field, value }) {
            commit('updateProductSoftwareField', { field, value });
        },
        async updateField({ commit }, { field, value }) {
            commit('updateField', { field, value });
        },
        async clearPluginVersions({ commit }, pluginId) {
            commit('clearPluginVersions', pluginId);
        },
        async setPluginVersions({ commit }, { pluginId, versions }) {
            commit('setPluginVersions', { pluginId, versions });
        },
        async fetchFormData({ commit }, { productId, env }) {
            const response = await formData(productId, env);
            commit('setProductData', response.result);
        },
        async fetchFormCreateData({ commit }, { productId, env} ) {
            const response = await formCreate(productId, env);
            commit('setFormCreateData', response.result);
        },
        async fetchFormCreatePluginsData({ commit }, { productId, env, moodleVersion, moodleVersionType, softwareId} ) {
            const response = await formCreatePlugins({
                "productId" : productId, 
                "env" : env, 
                "moodleVersion" : moodleVersion, 
                "moodleVersionType" : moodleVersionType,
                "softwareId" : softwareId
            });
            commit('setFormCreatePlugins', response.result);
        },
        async fetchPluginVersions({ commit }, params): Promise<any> {
            const response = await getPluginVersions(params.pluginId, params.moodleVersion, params.softwareId, params.softwareVersionType);
            commit('updatePlugins', { id:params.pluginId, field:'branch_versions', value: response.branches });
            commit('updatePlugins', { id:params.pluginId, field:'tag_versions', value: response.tags });
        },
        async updatePackagePlugins({ state },  { isSaveOnly }) {
            const selectedPlugins = state.formCreate.plugins
            .filter(plugin => plugin.included)
            .map(plugin => ({
                id: plugin.id,
                name: plugin.name,
                git_url: plugin.git_url,
                selected_version: plugin.selected_version,
                selected_version_type: plugin.selected_version_type,
                isEdited: plugin.isEdited ?? false
            }));
            const data = {
                software:{
                    id: state.product.software.id,
                    name: state.product.software.software_name,
                    software_id: state.product.software.software_id,
                    supported_version: state.product.software.supported_version,
                    supported_version_type: state.product.software.supported_version_type,
                },
                plugins:selectedPlugins,
                customer:state.product.customer,
                environment: state.selectedEnvironment,
                git_url: state.product.git_url,
                product_name: state.product.product_name,
                is_save_only: isSaveOnly
            }
            return await updateProductSettings(state.product.product_id, data);

        },
        async syncPluginsFromGit({ state }, {productId, env}) {
            const data = {
                env: env
            };
            await syncPluginsFromGit(productId, data);
        },
        async triggerBuildPipeline({ state }, {env, id}) {
            const data = {
                environment: env
            };
            await triggerBuildPipeline(id, data);
        },
        async pushPluginsToEnvironment({ state }, {env, editedPlugins}) {
            
            const selectedPlugins = state.formCreate.plugins
            .filter(plugin => plugin.included)
            .map(plugin => ({
                id: plugin.id,
                name: plugin.name,
                selected_version: plugin.selected_version,
                git_url: plugin.git_url,
                selected_version_type: plugin.selected_version_type,
                isEdited: editedPlugins.some(editedPlugin => editedPlugin.id === plugin.id)
            }));

            const data = {
                software:{
                    id: state.product.software.id,
                    name: state.product.software.software_name,
                    software_id: state.product.software.software_id,
                    supported_version: state.product.software.supported_version,
                    supported_version_type: state.product.software.supported_version_type,
                },
                plugins:selectedPlugins,
                customer:state.product.customer,
                git_url: state.product.git_url,
                product_name: state.product.product_name,
                environment: env,
            }
            return await updateProductPlugins(state.product.product_id, data);

        },
        async updateScheduleSettings({ state }) {
            let scheduleEnv = state.selectedEnvironment;
            if(scheduleEnv == "dev"){
               scheduleEnv = "development";
            }
            const selectedPlugins = state.formCreate.plugins
            .filter(plugin => plugin.included)
            .map(plugin => ({
                id: plugin.id,
                name: plugin.name,
                git_url: plugin.git_url,
                selected_version: plugin.selected_version,
                selected_version_type: plugin.selected_version_type,
                isEdited: plugin.isEdited ?? false
            }));
            const data = {
                software:{
                    id: state.product.software.id,
                    name: state.product.software.software_name,
                    software_id: state.product.software.software_id,
                    supported_version: state.product.software.supported_version,
                    supported_version_type: state.product.software.supported_version_type,
                },
                plugins:selectedPlugins,
                customer:state.product.customer,
                environment: state.selectedEnvironment,
                git_url: state.product.git_url,
                product_name: state.product.product_name,
                is_save_only: true
            }
            data[`${scheduleEnv}_schedule_hour`] = state.product[`${scheduleEnv}_schedule_hour`];
            data[`${scheduleEnv}_schedule_day`] = state.product[`${scheduleEnv}_schedule_day`];
            data[`${scheduleEnv}_schedule_month`] = state.product[`${scheduleEnv}_schedule_month`];

            return await updateProductSettings(state.product.product_id, data);
        },
        async updateProductMaintainer({ state }) {

            const data = {
                maintainerId:state.product.maintainer_id,
            }
            return await updateProductSettings(state.product.product_id, data);

        }
    },
    getters: {
        formCreate: state => state.formCreate,
        product: state => state.product,
        pluginById: state => pluginId => state.formCreate.plugins.find(plugin => plugin.id === pluginId),
        pluginVersionsById: (state, getters) => pluginId => {
            const plugin = getters.pluginById(pluginId);
            return plugin ? plugin.pluginVersions : [];
        }
    }
};
